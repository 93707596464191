// src/pages/contactus.jsx
import React from 'react';
import Head from '../components/head';

const AboutUs = () => (
<main>
<Head
title="About Us - ITS Projects Pty Ltd"
/>
	<section id="main" className="wrapper style2 about-main">
		<div className="title">
		<h1 className="page-title">About Us</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> ITS</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-briefcase"></i> About Us</li>
			</ol>
		</nav>
		</div>
		<div className="container text-center">
		
		<header className="style1">
		<h2>Your Trusted Wholesale Tile Supplier</h2>
		<p>At ITS Projects, we deliver premium tiles at unbeatable prices. From residential makeovers to commercial builds, we provide top-quality options and exceptional service tailored to your needs. Partner with us for affordability, reliability, and long-term value in every tile order.</p>
		</header>

		<img src="images/banner-partners.jpg" alt="ITS Projects Pty Ltd" className="img-fluid" />
		<div className="text-start">
		<p>We specialise in providing high-quality tiles at the most competitive prices in the market. With a strong commitment to affordability, we aim to empower our customers by helping them achieve the best value on a wide variety of tiles. </p>
		<p>
		Our extensive range includes premium options for any project, from residential renovations to large-scale commercial developments.
		</p>
		<p>Our company’s mission is to build long-lasting relationships with our clients by delivering outstanding service and unbeatable pricing. </p>
		<p>
		We believe that a successful business is built on trust and reliability, which is why we strive to be your dependable partner for all tile needs. Whether you're a contractor, designer, or retailer, ITS Projects Pty Ltd is here to support your projects with the best products, prices, and service.
		</p>
		<p>Let us help you achieve quality and affordability with every tile order. Reach out to ITS Projects Pty Ltd today and experience the difference of a dedicated wholesale tile partner.</p>
		</div>
		<ul className="actions special">
			<li><a href="/contact-us" className="button style1 large">Contact Us</a></li>
			<li><a href="/products" className="button style2 large">Explore Products</a></li>
			<li><a href="/inspiration" className="button style2 large">Inspiration</a></li>
		</ul>
		</div>


	</section>
	
</main>
);

export default AboutUs;