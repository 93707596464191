// src/components/Footer.jsx
import React from 'react';
import { useCurrentYear } from '../context/CurrentYearContext';

const Footer = () => {
  return (
            <section id="footer" className="wrapper">
                <div className="title">ITS Projects Pty Ltd</div>
                <div className="container">
                    <header className="style1">
                        <h2>Get in Touch with Our Expert Team</h2>
                        <p>
                        Have questions about our services or need guidance? Contact us today to explore how we can support your goals with personalised solutions and exceptional service.
                        </p>
                    </header>
                    <div className="row">
                        
                        <div className="col-12 col-12-medium">

                          
                                <section className="feature-list small">
                                    <div className="row">
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-home">Warehouse</h3>
                                                <p>
                                                2/26 Sterling Rd,<br />
                                                Minchibury, NSW, 2770
                                                </p>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-clock">Pick Up Hours</h3>
                                                <ul>
                                                    <li>Monday - Friday: 8:00 AM - 5:00 PM</li>
                                                    <li>Saturday: 9:00 AM - 4:00 PM</li>
                                                    <li>Sunday & Public holiday: Closed</li>
                                                </ul>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-envelope">Email</h3>
                                                <ul>
                                                <li><a href="mailto:info@itstiles.com.au">info@itstiles.com.au</a></li>
                                                </ul>
                                                <p>
                                                    
                                                </p>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-phone">Phone</h3>
                                                <ul><li>0413 577 933</li></ul>
                                            </section>
                                        </div>
                                    </div>
                                </section>

                        </div>
                    </div>
                    <div id="copyright">
                        <ul>
                            <li>&copy; {useCurrentYear()} ITS Projects Pty Ltd. All rights reserved.</li><li></li>
                        </ul>
                    </div>
                </div>
            </section>




    );
};
export default Footer;
