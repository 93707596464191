// src/components/navpanel.jsx
import React, { useState, useEffect, useRef } from 'react';

const NavPanel = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navPanelRef = useRef(null);
  const titleBarRef = useRef(null);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navPanelRef.current &&
        !navPanelRef.current.contains(event.target) &&
        titleBarRef.current &&
        !titleBarRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };

    const handleLinkClick = (event) => {
      if (event.target.tagName === 'A') {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener('pointerdown', handleClickOutside);
      navPanelRef.current.addEventListener('click', handleLinkClick);
    } else {
      document.removeEventListener('pointerdown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
      if (navPanelRef.current) {
        navPanelRef.current.removeEventListener('click', handleLinkClick);
      }
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('navPanel-visible');
    } else {
      document.body.classList.remove('navPanel-visible');
    }
    document.body.classList.remove('is-preload');
  }, [isMenuOpen]);

  return (
    <div>
      <div id="titleBar" ref={titleBarRef}>
        <a href="#" onClick={toggleMenu} className="toggle"></a>
      </div>
      <div id="navPanel" ref={navPanelRef}>
        <nav>
          <a className="link depth-0" href="/"><span className="indent-0"></span>ITS Projects Pty Ltd</a>
          <a className="link depth-0" href="/about-us"><span className="indent-0"></span>About Us</a>
          <a className="link depth-0" href="/contact-us"><span className="indent-0"></span>Contact Us</a>
          <a className="link depth-0" href="/products"><span className="indent-0"></span>Products</a>
          <a className="link depth-0" href="/inspiration"><span className="indent-0"></span>Inspiration</a>
        </nav>
      </div>
    </div>
  );
};

export default NavPanel;