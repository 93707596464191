import React from 'react';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ show, handleClose, imageSrc, imageAlt }) => {
    return (
      <Modal show={show} onHide={handleClose} centered dialogClassName="fullscreen-modal">
        <Modal.Body>
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{
              objectFit: 'contain', // Ensures the image scales while maintaining aspect ratio
            }}
          />
        </Modal.Body>
      </Modal>
    );
  };

export default ImageModal;