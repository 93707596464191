import React, { useState } from 'react';
import Head from '../components/head';
import ImageModal from '../components/imagemodal';
import useScrollToHash from '../hooks/useScrollToHash';

const Inspiration = () => {
  useScrollToHash();
	const [showModal, setShowModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
  
	const handleImageClick = (thumbnailSrc) => {
	  // Replace "_w360" in the thumbnail name to get the full-size image
	  const originalImageSrc = thumbnailSrc.replace('_w360', '');
	  setSelectedImage(originalImageSrc); // Set the full-size image
	  setShowModal(true); // Show the modal
	};
  
	const handleCloseModal = () => {
	  setShowModal(false);
	  setSelectedImage(''); // Clear the selected image
	};
  
  
	return (
<main>
	<Head title="Inspiration - ITS Projects Pty Ltd" />
    <section id="highlights" className="wrapper style3">
		<div className="title">
		<h1 className="page-title">Inspiration</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> ITS</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-handshake"></i> Inspiration</li>
			</ol>
		</nav>
		</div>
		<div className="container">
          <header className="style1">
            <h2>Transform Your Spaces with Creative Inspiration</h2>
            <p className="mb-0 text-center">
              Explore our gallery of ideas and innovative designs to find the perfect inspiration for your next project. From modern aesthetics to timeless classics, ITS Projects Pty Ltd helps you envision spaces that blend style and functionality seamlessly.
            </p>
          </header>

          {/* Image Gallery */}
          <div className="row">
            {[
              'images/111_w360.jpg',
              'images/112_w360.jpg',
              'images/113_w360.jpg',
              'images/114_w360.jpg',
              'images/115_w360.jpg',
              'images/122_w360.jpg',
              'images/123_w360.jpg',
              'images/118_w360.jpg',
              'images/119_w360.jpg',
              'images/120_w360.jpg',
              'images/121_w360.jpg',
              'images/116_w360.jpg',
			  'images/117_w360.jpg',
            ].map((thumbnailSrc, index) => (
              <div
                className={`col-${index < 9 ? 4 : 6} ps-2`}
                key={index}
              >
                <section className="highlight">
                  <a
                    href="#!"
                    className="image featured"
                    onClick={(e) => {
                      e.preventDefault();
                      handleImageClick(thumbnailSrc);
                    }}
                  >
                    <img
                      src={thumbnailSrc}
                      alt={`Inspiration ${index + 1}`}
                      className="img-fluid"
                    />
                  </a>
                </section>
              </div>
            ))}
          </div>

          {/* Action Buttons */}
          <ul className="actions special">
            <li>
              <a href="/contact-us" className="button style1 large">
                Contact Us
              </a>
            </li>
            <li>
              <a href="/products" className="button style2 large">
                Products
              </a>
            </li>
          </ul>
        </div>
      </section>

      {/* Modal for Full-Size Image */}
      {selectedImage && (
        <ImageModal
          show={showModal}
          handleClose={handleCloseModal}
          imageSrc={selectedImage}
          imageAlt="Original Inspiration Image"
        />
      )}
    </main>
  );
};

export default Inspiration;