import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1); // Extract the ID from the hash
      const scrollToElement = () => {
        const element = document.getElementById(id); // Find the target element
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

      // Wait for the DOM to be fully loaded
      const timeout = setTimeout(scrollToElement, 200); // Adjust the delay as needed

      return () => clearTimeout(timeout); // Cleanup on component unmount
    }
  }, [location]); // Rerun whenever the URL changes
};

export default useScrollToHash;