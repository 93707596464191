import React, { createContext, useContext } from 'react';

// Create the Context
const CurrentYearContext = createContext();

// Create a Provider Component
export const CurrentYearProvider = ({ children }) => {
  const currentYear = new Date().getFullYear();

  return (
    <CurrentYearContext.Provider value={currentYear}>
      {children}
    </CurrentYearContext.Provider>
  );
};

// Create a custom hook to use the CurrentYearContext
export const useCurrentYear = () => {
    const context = useContext(CurrentYearContext);
    if (context === undefined) {
      console.warn('useCurrentYear must be used within a CurrentYearProvider');
      return new Date().getFullYear(); // Fallback
    }
    return context;
  };