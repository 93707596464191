// src/pages/home.jsx
import React from 'react';
import { useCurrentYear } from '../context/CurrentYearContext';
import Head from '../components/head';
import useScrollToHash from '../hooks/useScrollToHash';
const Home = () => {
	useScrollToHash(); // Enable scrolling to anchors
	const currentYear = useCurrentYear();
	return (
<main>
<Head
title="Home - ITS Projects Pty Ltd"
/>
  <section id="intro" className="wrapper style1">
		<div className="title">
		<h1 className="page-title">Welcome to ITS</h1>
		</div>
		<div className="container">
			<p className="style2">
			Your Trusted <br className="mobile-hide" />Wholesale Tile Supplier</p>
			<p className="style3">At ITS Projects, we deliver premium tiles at unbeatable prices. From residential makeovers to commercial builds, we provide top-quality options and exceptional service tailored to your needs. Partner with us for affordability, reliability, and long-term value in every tile order.</p>
			<ul className="actions">
				<li><a href="/contact-us" className="button style3 large">Contact Us</a></li>
				<li><a href="/about-us" className="button style3 large">More Info</a></li>
			</ul>
		</div>
	</section>


	<section id="main" className="wrapper style2">
		<div className="title">Popular Ranges</div>
		<div className="container">
				<a href="/products" className="image featured">
					<img src="images/pic01.jpg" alt="" />
				</a>
				<section id="features">
					<header className="style1">
					<h2 id="tile-trends">Trend In Tiles in {currentYear}</h2>
						<p></p>
					</header>
					<div className="feature-list">
						<div className="row">
							<div className="col-6 col-12-medium">
								<section className="ps-0">
									<a href="/products#porcelain" className="image left"><img src="images/porcelain.jpg" alt="porcelain" /></a>
									<h3><a href="/products#porcelain">Porcelain</a></h3>
									<p>Cottage collection - Cotto Hexagon & Sand Hexagon</p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
							<section className="ps-0">
							<a href="/products#ceramic" className="image left"><img src="images/ceramic.jpg" alt="ceramic" /></a>
									<h3><a href="/products#ceramic">Ceramic</a></h3>
									<p>Zelij Collection - Chalk Matt & Blush Gloss</p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
							<section className="ps-0">
							<a href="/products#terrazzo" className="image left"><img src="images/terrazzo.jpg" alt="terrazzo" /></a>
									<h3><a href="/products#terrazzo">Terrazzo Stone</a></h3>
									<p>Terrazzo Collection - Cloudburst & Flannel Flower</p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
							<section className="ps-0">
							<a href="/products#terracotta" className="image left"><img src="images/terracotta.jpg" alt="Terracotta" /></a>
									<h3><a href="/products#terracotta">Terracotta</a></h3>
									<p>Malina Terracotta collection - Parquet Light & Dark & Rectangle Light & Dark</p>
								</section>
							</div>
						</div>
					</div>
					<ul className="actions special">
						<li><a href="/contact-us" className="button style1 large">Contact Us</a></li>
						<li><a href="/products" className="button style2 large">More Products</a></li>
					</ul>
				</section>

		</div>
	</section>

	<section id="highlights" className="wrapper style3">
		<div className="title">Inspiration</div>
		<div className="container">
			<div className="row aln-center">
				<div className="col-4 col-12-medium">
					<section className="highlight">
						<a href="/inspiration#highlights" className="image featured"><img src="images/pic02.jpg" alt="" /></a>
					</section>
				</div>
				<div className="col-4 col-12-medium">
					<section className="highlight">
						<a href="/inspiration#highlights" className="image featured"><img src="images/pic03.jpg" alt="" /></a>
					</section>
				</div>
				<div className="col-4 col-12-medium">
					<section className="highlight">
						<a href="/inspiration#highlights" className="image featured"><img src="images/pic04.jpg" alt="" /></a>
					</section>
				</div>

				<ul className="actions special">
				<li><a href="/contact-us" className="button style1 large">Contact Us</a></li>
					<li><a href="/inspiration" className="button style2 large">More Inspiration</a></li>
				</ul>
			</div>
		</div>
	</section>


</main>
 );
};


export default Home;