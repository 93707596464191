// src/pages/notfound.jsx
import Head from '../components/head';
const NotFound = () => (
  <main>
    <Head
title="Page Not Found - ITS Projects Pty Ltd"
/>
    <section id="main" className="wrapper style2">
      <div className="title">
      <h1 className="page-title">Page Not Found</h1>
      <nav aria-label="breadcrumb" className="breadcrumb-container">
        <ol className="breadcrumb slim-breadcrumb">
          <li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> ITS</a></li>
          <li className="breadcrumb-item active" aria-current="page"><i className="fas fa-exclamation-triangle"></i> Not Found</li>
        </ol>
      </nav>
      </div>
      <div className="container">
          <section id="features">
            <header className="style1">
              <h2>Oops!</h2>
              <p>The page you’re looking for doesn’t exist. Return to the <a href="/">homepage</a> or explore the <a href="/products">products</a> o find what you need. Let’s get you back on track with ITS Projects Pty Ltd.</p>
            </header>
          </section>      
    </div>
    </section>       
  </main>
);

export default NotFound;