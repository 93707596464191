import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home';
import Inspiration from './pages/inspiration';
import ContactUs from './pages/contactus';
import AboutUs from './pages/aboutus';
import Products from './pages/products';
import NotFound from './pages/notfound';
import Header from './components/header';
import Footer from './components/footer';
import NavPanel from './components/navpanel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const App = () => {
  const location = useLocation();

  // Define a mapping between routes and class names
  const routeClassNames = {
    '/': 'home',
    '/about-us': 'aboutus',
    '/inspiration': 'inspiration',
    '/contact-us': 'contactus',
    '/content': 'contentpage',
    '/products': 'products',
  };

  // Set headerClass based on the current pathname, defaulting to "home" if no match
  const headerClass = routeClassNames[location.pathname] || 'home';

  return (
    <>
      <Header className={headerClass} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inspiration" element={<Inspiration />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <NavPanel />
    </>
  );
};

export default App;