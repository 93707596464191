import React from 'react';
import useScrollToHash from '../hooks/useScrollToHash';
import Head from '../components/head';
const Products = () => {
    useScrollToHash(); // Activate scroll-to-anchor behavior
  
    return (
<main>
<Head
title="Products - ITS Projects Pty Ltd"
/>
<section id="main" className="wrapper style2 py-5 feature container-fluid">
		<div className="title">
        <h1 className="page-title">Products</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> ITS</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-cogs"></i> Products</li>
			</ol>
		</nav>
        </div>
		<div className="container pb-5">
                <header className="style1">
                    <h2>Discover Premium Tiles for Every Project</h2>
                    <p className="mb-0 text-center">ITS Projects Pty Ltd offers an extensive range of high-quality tiles designed to suit any project. From elegant porcelain to durable terracotta, we provide innovative solutions to elevate your residential or commercial spaces with unmatched affordability and reliability.
                    </p>
                </header>
                <div className="row product-type ps-5">
                    <div className="col-md-4">
                        <h4>Tiles by Type</h4>
                        <ul>
                            <li>Porcelain</li>
                            <li>Ceramic</li>
                            <li>Terrazzo Stone</li>
                            <li>Terracotta</li>
                            <li>Mosaics</li>
                            <li>Pool Tiles</li>
                            <li>Pavers</li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4">
                        <h4>Tiles by Look</h4>
                        <ul>
                            <li>Concrete</li>
                            <li>Marble </li>
                            <li>Travertine</li>
                            <li>Timber</li>
                            <li>Encaustic</li>
                            <li>Mosaic</li>
                            <li>Subway</li>
                            <li>Terracotta</li>
                            <li>Coping</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>Tiles by Finishing</h4>
                        <ul>
                            <li>External</li>
                            <li>In& Out solution</li>
                            <li>Polished </li>
                            <li>Semi-polished</li>
                            <li>Matt finish</li>
                            <li>Honed</li>
                            <li>Lappato</li>
                            <li>Stone touch</li>
                        </ul>
                    </div>
                </div>
                <div className="row g-4">
                    <div id="porcelain" className="col-md-6 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.8s" >
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                            <img src="images/211.png" alt="porcelain" className="img-fluid" />
                            </div>
                            <h4>Porcelain</h4>
                            <p className="mb-4 text-center">Cottage collection <br/> Cotto Hexagon & Sand Hexagon 
                            </p>
                            <a className="button style1" href="/contact-us">Contact Us</a>
                        </div>
                    </div>
					<div id="ceramic" className="col-md-6 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                            <img src="images/212.png" alt="ceramic" className="img-fluid" />
                            </div>
                            <h4>Ceramic</h4>
                            <p className="mb-4 text-center">Zelij Collection <br/> Chalk Matt & Blush Gloss 
                            </p>
                            <a className="button style1" href="/contact-us">Contact Us</a>
                        </div>
                    </div>
					<div id="terrazzo" className="col-md-6 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                            <img src="images/213.png" alt="terrazzo" className="img-fluid" />
                            </div>
                            <h4>Terrazzo Stone</h4>
                            <p className="mb-4 text-center">Terrazzo Collection <br/> Cloudburst & Flannel Flower 
                            </p>
                            <a className="button style1" href="/contact-us">Contact Us</a>
                        </div>
                    </div>
					<div id="terracotta" className="col-md-6 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                            <img src="images/214.png" alt="terracotta" className="img-fluid" />
                            </div>
                            <h4>Terracotta</h4>
                            <p className="mb-4 text-center">Malina Terracotta collection <br/> Parquet Light & Dark & Rectangle Light & Dark 
                            </p>
                            <a className="button style1" href="/contact-us">Contact Us</a>
                        </div>
                    </div>
                </div>
           
		</div>
	</section>
</main>
  );
};

export default Products;